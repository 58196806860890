<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import TextField from '@/components/input/TextField.vue'
import { computed, inject, nextTick, provide, reactive, ref } from 'vue'
import { keyOrganizationId, keyProjectId } from '@/app'
import AttributesInput, { AttributeDataList } from '@/components/events/AttributesInput.vue'
import EditDialog from '@/components/dialogs/EditDialog.vue'
import type { Event, UnsetAttributeCommand } from '@/generated/graphql'
import EventStartAndEnd from '@/components/input/EventStartAndEnd.vue'
import type { MutationModel } from '@/components/events/shared'
import { createValidation, keyEventDuplication, keyEventStart } from '@/components/events/shared'
import NotificationFailed from '@/components/notifications/NotificationFailed.vue'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'

const props = defineProps<{ modelValue?: Event }>()
const emit = defineEmits<{
  'update:modelValue': [value?: Event]
  updated: []
  cancel: []
}>()

const mutationModel = reactive<MutationModel>({
  id: props.modelValue.id,
  name: props.modelValue.name,
  start: props.modelValue.start,
  startTimezone: props.modelValue.startTimezone,
  end: props.modelValue.end,
  endTimezone: props.modelValue.endTimezone
})
const validation = createValidation(mutationModel, false)
provide(
  keyEventStart,
  computed(() => mutationModel.start)
)
provide(
  keyEventDuplication,
  computed(() => false)
)

const attributesSetList = ref<AttributeDataList>()
const attributesUnsetList = ref<UnsetAttributeCommand[]>()

const updateEventMutation = useMutation(
  gql`
    mutation updateEvent(
      $orgId: ID!
      $projectId: ID!
      $command: UpdateEventCommand!
      $unsetCommands: [UnsetAttributeCommand!]!
      $addressCommands: [SetAddressAttributeOnEventCommand!]!
      $choiceCommands: [SetChoiceAttributesOnEventCommand!]!
      $integerCommands: [SetIntegerAttributeOnEventCommand!]!
      $fileCommands: [SetFileAttributesOnEventCommand!]!
      $flightCommands: [SetFlightAttributeOnEventCommand!]!
      $linkCommands: [SetLinkAttributesOnEventCommand!]!
      $nocturnalCommands: [SetNocturnalAttributeOnEventCommand!]!
      $toggleCommands: [SetToggleAttributeOnEventCommand!]!
      $textCommands: [SetTextAttributeOnEventCommand!]!
      $resourceCommands: [SetResourceAttributesOnEventCommand!]!
    ) {
      organization(id: $orgId) {
        project(id: $projectId) {
          events {
            updateEvent(input: $command) {
              id
            }
          }
          eventAttributes {
            unsetMultipleAttributes(input: $unsetCommands) {
              id
            }
            setMultipleAddressAttributesOnEvent(input: $addressCommands) {
              id
            }
            setMultipleChoiceAttributesOnEvent(input: $choiceCommands) {
              id
            }
            setMultipleFileAttributesOnEvent(input: $fileCommands) {
              id
            }
            setMultipleFlightAttributesOnEvent(input: $flightCommands) {
              id
            }
            setMultipleIntegerAttributesOnEvent(input: $integerCommands) {
              id
            }
            setMultipleLinkAttributesOnEvent(input: $linkCommands) {
              id
            }
            setMultipleNocturnalAttributesOnEvent(input: $nocturnalCommands) {
              id
            }
            setMultipleToggleAttributesOnEvent(input: $toggleCommands) {
              id
            }
            setMultipleTextAttributesOnEvent(input: $textCommands) {
              id
            }
            setMultipleResourceAttributesOnEvent(input: $resourceCommands) {
              id
            }
          }
        }
      }
    }
  `
)

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!
const notificationFail = ref(false)
const notificationUpdated = ref(false)
function update() {
  updateEventMutation
    .mutate({
      command: mutationModel,
      orgId: orgId.value,
      projectId: projectId.value,
      unsetCommands: attributesUnsetList.value,
      addressCommands: attributesSetList.value['ADDRESS'],
      choiceCommands: attributesSetList.value['CHOICE'],
      fileCommands: attributesSetList.value['FILE'],
      flightCommands: attributesSetList.value['FLIGHT'],
      integerCommands: attributesSetList.value['INTEGER'],
      linkCommands: attributesSetList.value['LINK'],
      nocturnalCommands: attributesSetList.value['NOCTURNAL'],
      toggleCommands: attributesSetList.value['TOGGLE'],
      textCommands: attributesSetList.value['TEXT'],
      resourceCommands: attributesSetList.value['RESOURCE']
    })
    .then(() => {
      notificationUpdated.value = true
      emit('updated')
    })
}
</script>

<template>
  <edit-dialog
    :model-value="!!props.modelValue"
    :validation="validation"
    @save="update()"
    @cancel="$emit('cancel')"
    @update:modelValue="(v) => emit('update:modelValue', undefined)"
    persistent
  >
    <text-field label="Name" v-model="mutationModel.name" required :validation="validation.name" />
    <event-start-and-end
      v-model:start="mutationModel.start"
      :start-validation="validation.start"
      v-model:startTimezone="mutationModel.startTimezone"
      :start-timezone-validation="validation.startTimezone"
      v-model:end="mutationModel.end"
      :end-validation="validation.end"
      v-model:endTimezone="mutationModel.endTimezone"
      :end-timezone-validation="validation.endTimezone"
    />
    <attributes-input
      :event-id="mutationModel.id"
      :event-type-id="props.modelValue.type.id"
      :seeds="props.modelValue.attributes"
      @setAttributes="
        (v) => {
          attributesSetList = v
        }
      "
      @unsetAttributes="
        (v) => {
          attributesUnsetList = v
        }
      "
      @set-start-and-end="
        (start, startTimezone, end, endTimezone) => {
          mutationModel.start = start
          mutationModel.startTimezone = startTimezone
          mutationModel.endTimezone = endTimezone
          nextTick(() => (mutationModel.end = end))
        }
      "
    />
  </edit-dialog>

  <notification-saved v-model="notificationUpdated" />
  <notification-failed v-model="notificationFail" />
</template>

<style scoped lang="scss"></style>
