<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import TextField from '@/components/input/TextField.vue'
import { computed, inject, nextTick, provide, reactive, ref, watch } from 'vue'
import EventTypePicker from '@/components/input/EventTypePicker.vue'
import { keyOrganizationId, keyProjectId } from '@/app'
import AttributesInput, { AttributeDataList } from '@/components/events/AttributesInput.vue'
import EditDialog from '@/components/dialogs/EditDialog.vue'
import type { AttributeUnion, Event, UnsetAttributeCommand } from '@/generated/graphql'
import EventStartAndEnd from '@/components/input/EventStartAndEnd.vue'
import type { MutationModel } from '@/components/events/shared'
import { createValidation, keyEventDuplication, keyEventStart } from '@/components/events/shared'
import NotificationFailed from '@/components/notifications/NotificationFailed.vue'
import NotificationSaved from '@/components/notifications/NotificationSaved.vue'
import { v4 } from 'uuid'
import { omit } from 'underscore'

const props = defineProps<{
  modelValue: boolean
  initDate?: string
  seed?: Event
}>()
const emit = defineEmits<{
  'update:modelValue': [value: boolean]
  duplicated: [value: string]
}>()

const initialState = {
  id: v4(),
  name: undefined,
  typeId: undefined,
  start: undefined,
  startTimezone: undefined,
  end: undefined,
  endTimezone: undefined
}
const mutationModel = reactive<MutationModel>({ ...initialState })
const validation = createValidation(mutationModel, true)
watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      Object.assign(mutationModel, initialState)
      mutationModel.id = v4()
      mutationModel.name = props.seed?.name
      mutationModel.typeId = props.seed?.type?.id
      mutationModel.start = props.seed?.start || props.initDate
      mutationModel.startTimezone = props.seed?.startTimezone
      mutationModel.end = props.seed?.end || props.initDate
      mutationModel.endTimezone = props.seed?.endTimezone

      validation.value.$reset()
    }
  }
)
provide(
  keyEventStart,
  computed(() => mutationModel.start)
)
provide(
  keyEventDuplication,
  computed(() => !!props.seed)
)

const attributesSetList = ref<AttributeDataList>()
const attributesUnsetList = ref<UnsetAttributeCommand[]>()
const attributesSeed = computed<AttributeUnion[]>(
  () => props.seed?.attributes.map((a: AttributeUnion) => omit(a, 'id')) || []
)

const createEventMutation = useMutation(
  gql`
    mutation createEvent($orgId: ID!, $projectId: ID!, $command: CreateEventCommand!) {
      organization(id: $orgId) {
        project(id: $projectId) {
          events {
            createEvent(input: $command) {
              id
            }
          }
        }
      }
    }
  `,
  {
    refetchQueries: ['getCalendarEvents']
  }
)
const createAttributesMutation = useMutation(
  gql`
    mutation createEventAttributes(
      $orgId: ID!
      $projectId: ID!
      $unsetCommands: [UnsetAttributeCommand!]!
      $addressCommands: [SetAddressAttributeOnEventCommand!]!
      $choiceCommands: [SetChoiceAttributesOnEventCommand!]!
      $fileCommands: [SetFileAttributesOnEventCommand!]!
      $flightCommands: [SetFlightAttributeOnEventCommand!]!
      $integerCommands: [SetIntegerAttributeOnEventCommand!]!
      $linkCommands: [SetLinkAttributesOnEventCommand!]!
      $nocturnalCommands: [SetNocturnalAttributeOnEventCommand!]!
      $toggleCommands: [SetToggleAttributeOnEventCommand!]!
      $textCommands: [SetTextAttributeOnEventCommand!]!
      $resourceCommands: [SetResourceAttributesOnEventCommand!]!
    ) {
      organization(id: $orgId) {
        project(id: $projectId) {
          eventAttributes {
            unsetMultipleAttributes(input: $unsetCommands) {
              id
            }
            setMultipleAddressAttributesOnEvent(input: $addressCommands) {
              id
            }
            setMultipleChoiceAttributesOnEvent(input: $choiceCommands) {
              id
            }
            setMultipleFlightAttributesOnEvent(input: $flightCommands) {
              id
            }
            setMultipleFileAttributesOnEvent(input: $fileCommands) {
              id
            }
            setMultipleIntegerAttributesOnEvent(input: $integerCommands) {
              id
            }
            setMultipleLinkAttributesOnEvent(input: $linkCommands) {
              id
            }
            setMultipleNocturnalAttributesOnEvent(input: $nocturnalCommands) {
              id
            }
            setMultipleToggleAttributesOnEvent(input: $toggleCommands) {
              id
            }
            setMultipleTextAttributesOnEvent(input: $textCommands) {
              id
            }
            setMultipleResourceAttributesOnEvent(input: $resourceCommands) {
              id
            }
          }
        }
      }
    }
  `
)

const orgId = inject(keyOrganizationId)!
const projectId = inject(keyProjectId)!

const createInProgress = ref(false)
const notificationFail = ref(false)
const notificationCreated = ref(false)
function create() {
  createInProgress.value = true
  createEventMutation
    .mutate({
      command: mutationModel,
      orgId: orgId.value,
      projectId: projectId.value
    })
    .then(() => {
      return createAttributesMutation.mutate({
        orgId: orgId.value,
        projectId: projectId.value,
        unsetCommands: attributesUnsetList.value,
        addressCommands: attributesSetList.value['ADDRESS'],
        choiceCommands: attributesSetList.value['CHOICE'],
        fileCommands: attributesSetList.value['FILE'],
        flightCommands: attributesSetList.value['FLIGHT'],
        integerCommands: attributesSetList.value['INTEGER'],
        linkCommands: attributesSetList.value['LINK'],
        nocturnalCommands: attributesSetList.value['NOCTURNAL'],
        toggleCommands: attributesSetList.value['TOGGLE'],
        textCommands: attributesSetList.value['TEXT'],
        resourceCommands: attributesSetList.value['RESOURCE']
      })
    })
    .then(() => {
      createInProgress.value = false
      notificationCreated.value = true

      if (props.seed) {
        emit('duplicated', mutationModel.id)
      }
      emit('update:modelValue', false)
    })
    .catch(() => {
      createInProgress.value = false
    })
}
</script>

<template>
  <edit-dialog
    :model-value="props.modelValue"
    :validation="validation"
    @update:modelValue="(v) => $emit('update:modelValue', v)"
    create
    @save="create"
    :save-loading="createInProgress"
    persistent
  >
    <text-field label="Name" v-model="mutationModel.name" :validation="validation.name" required />
    <event-type-picker
      label="Type"
      v-model="mutationModel.typeId"
      :validation="validation.typeId"
      required
    />
    <event-start-and-end
      v-model:start="mutationModel.start"
      :start-validation="validation.start"
      v-model:startTimezone="mutationModel.startTimezone"
      :start-timezone-validation="validation.startTimezone"
      v-model:end="mutationModel.end"
      :end-validation="validation.end"
      v-model:endTimezone="mutationModel.endTimezone"
      :end-timezone-validation="validation.endTimezone"
    />
    <attributes-input
      :event-id="mutationModel.id"
      :event-type-id="mutationModel.typeId"
      :seeds="attributesSeed"
      @setAttributes="
        (v) => {
          attributesSetList = v
        }
      "
      @unsetAttributes="
        (v) => {
          attributesUnsetList = v
        }
      "
      @set-start-and-end="
        (start, startTimezone, end, endTimezone) => {
          mutationModel.start = start
          mutationModel.startTimezone = startTimezone
          mutationModel.endTimezone = endTimezone
          nextTick(() => (mutationModel.end = end))
        }
      "
    />
  </edit-dialog>

  <notification-saved v-model="notificationCreated" />
  <notification-failed v-model="notificationFail" />
</template>

<style scoped lang="scss"></style>
