<script setup lang="ts">
import { ErrorResponse } from '@apollo/client/link/error'
import { computed, ref, watch } from 'vue'
import { DateTime } from 'luxon'
import { apiErrors } from '@/apollo'

export type ApiErrors = Omit<ErrorResponse, 'nextLink'>
const snackbarOpen = computed(() => !!apiErrors.value)
const detailsOpen = ref(false)

const userAgent = computed(() => window.navigator.userAgent)
const errorTime = ref(DateTime.now())
watch(apiErrors, () => {
  errorTime.value = DateTime.now()
})

type ErrorType = 'unknown' | 'apiUnreachable' | 'privileges'
const errorType = computed<ErrorType>(() => {
  if (!apiErrors.value) {
    return 'unknown'
  }

  const networkErrorMessage = apiErrors.value?.networkError?.message.toLowerCase()
  if (networkErrorMessage?.includes('failed to fetch')) {
    return 'apiUnreachable'
  }

  const isForbidden = apiErrors.value?.graphQLErrors?.some(
    (e) => e.message.toLowerCase() == 'forbidden'
  )
  if (isForbidden) {
    return 'privileges'
  }

  return 'unknown'
})

const copied = ref<boolean>()
async function copy() {
  const text = document.getElementById('copy-errors')?.innerText as string

  try {
    await window.navigator.clipboard.writeText(text)
    copied.value = true
  } catch (err) {
    copied.value = false
  }
  setTimeout(() => {
    copied.value = undefined
  }, 2000)
}
</script>

<template>
  <v-snackbar :model-value="snackbarOpen" timeout="-1" color="error" vertical>
    <h3>Something went wrong</h3>

    <template v-if="errorType == 'apiUnreachable'"> Cannot reach the API </template>
    <template v-if="errorType == 'privileges'">
      You are not authorized to perform this action or you're no longer logged in.
    </template>
    <template v-if="errorType == 'unknown'"> An unknown error has occurred </template>

    <template #actions>
      <v-btn @click="detailsOpen = true"> More details </v-btn>
      <v-btn @click="apiErrors = undefined"> Dismiss </v-btn>
    </template>
  </v-snackbar>

  <v-dialog v-model="detailsOpen">
    <v-card>
      <v-card-title> Details of the error </v-card-title>
      <v-card-text>
        <div id="errors">
          <h3>Meta</h3>
          {{ errorTime }}<br />
          {{ userAgent }}

          <h3>Response</h3>
          <pre>{{ JSON.stringify(apiErrors?.response || 'None', null, 2) }}</pre>

          <h3>Network error</h3>
          <pre>{{ JSON.stringify(apiErrors?.networkError || 'None', null, 2) }}</pre>

          <h3>GraphQL errors</h3>
          <pre>{{ JSON.stringify(apiErrors?.graphQLErrors || 'None', null, 2) }}</pre>

          <h3>Operation</h3>
          <pre>{{ JSON.stringify(apiErrors?.operation || 'None', null, 2) }}</pre>
        </div>
        <div id="copy-errors">
          <pre>
Meta
{{ errorTime }}
{{ userAgent }}

Response:
{{ JSON.stringify(apiErrors?.response || 'None', null, 2) }}

Network error
{{ JSON.stringify(apiErrors?.networkError || 'None', null, 2) }}

GraphQL errors
{{ JSON.stringify(apiErrors?.graphQLErrors || 'None', null, 2) }}

Operation
{{ JSON.stringify(apiErrors?.operation || 'None', null, 2) }}
          </pre>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="copy()">
          <template #append>
            <v-icon
              v-if="copied !== undefined"
              :icon="copied ? 'done' : 'cancel'"
              :color="copied ? 'success' : 'error'"
            />
          </template>
          Copy
        </v-btn>
        <v-btn color="primary" variant="elevated" @click="detailsOpen = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
h3 {
  margin-top: 1em;
}

#errors {
  max-height: 70vh;
  overflow-y: auto;
}
#copy-errors {
  display: none;
}
</style>
