<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable'
import type { AttributeUnion, Event, Query } from '@/generated/graphql'
import { computed, inject, provide, ref, watch } from 'vue'
import { authzCanWrite, keyOrganizationId, keyProjectId, toDateTime, toTime } from '@/app'
import { DateTime } from 'luxon'
import { getEventQuery } from '@/components/events/getEventQuery'
import gql from 'graphql-tag'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import UpdateEvent from '@/components/events/UpdateEvent.vue'
import markdownItLinkAttributes from 'markdown-it-link-attributes'
import MarkdownIt from 'markdown-it'
import NotificationFailed from '@/components/notifications/NotificationFailed.vue'
import NotificationDeleted from '@/components/notifications/NotificationDeleted.vue'
import ViewFileAttribute from '@/components/events/attributes/ViewFileAttribute.vue'
import { keyEvent } from '@/components/events/shared'
import ViewFlightAttribute from '@/components/events/attributes/ViewFlightAttribute.vue'

const props = defineProps<{
  id: string
}>()
const emit = defineEmits<{
  refresh: []
  close: []
  duplicate: [value: Event]
}>()

const isOpen = ref(true)
watch(isOpen, () => {
  if (!isOpen.value) {
    emit('close')
  }
})

const orgId = inject(keyOrganizationId)
const projectId = inject(keyProjectId)
const fetchQuery = useQuery<Query>(getEventQuery, () => ({
  orgId: orgId?.value,
  projectId: projectId?.value,
  eventId: props.id
}))
const event = computed<Event | undefined>(
  () => fetchQuery.result.value?.organization.project.event || undefined
)
provide(keyEvent, event)
const attributes = computed<AttributeUnion>(() =>
  event.value
    ? JSON.parse(JSON.stringify(event.value.attributes)).sort(
        (a: AttributeUnion, b: AttributeUnion) => a.meta?.position - b.meta?.position || 0
      )
    : []
)
const isSameDay = computed<boolean>(() => {
  if (!event.value) {
    return false
  }

  return DateTime.fromISO(event.value?.start).hasSame(DateTime.fromISO(event.value?.end), 'day')
})

const failNotification = ref(false)

const updateItem = ref<Event>()
function updated() {
  updateItem.value = undefined
  fetchQuery.refetch()
  emit('refresh')
}

const askDeleteOpen = ref(false)
const deleteNotification = ref(false)
const deleteMutation = useMutation(gql`
  mutation deleteEvent($orgId: ID!, $projectId: ID!, $eventId: ID!) {
    organization(id: $orgId) {
      project(id: $projectId) {
        events {
          deleteEvent(input: { id: $eventId }) {
            id
          }
        }
      }
    }
  }
`)
function doDelete() {
  deleteMutation
    .mutate({
      orgId: orgId?.value,
      projectId: projectId?.value,
      eventId: event.value?.id
    })
    .then(() => {
      deleteNotification.value = true
      emit('refresh')
      emit('close')
    })
}

const md = MarkdownIt({
  breaks: false,
  html: false,
  linkify: true
})
md.use(markdownItLinkAttributes, {
  attrs: {
    target: '_blank',
    rel: 'noopener'
  }
})
function markdown(str?: string) {
  if (!str) {
    return undefined
  }
  let ret = md.renderInline(str)
  ret = ret.replace(/([() \-+\d]{8,})/gm, '<a href="tel:$1">$1</a>')

  return ret
}
</script>

<template>
  <v-navigation-drawer v-model="isOpen" location="right" :width="600" temporary>
    <div class="event-drawer">
      <template v-if="!!event">
        <div class="event-actions" v-if="authzCanWrite">
          <v-icon icon="content_copy" title="Duplicate event" @click="$emit('duplicate', event)" />
          <v-icon icon="edit" title="Edit event" @click="updateItem = event" />
          <v-icon icon="delete" title="Delete event" @click="askDeleteOpen = true" />
        </div>

        <h2>{{ event.name }}</h2>
        <p id="event-info">
          {{ toDateTime(event.start) }} -
          {{ isSameDay ? toTime(event.end) : toDateTime(event.end) }}
        </p>

        <template v-for="attr in attributes" :key="attr.id">
          <h3>{{ attr.meta.name }}</h3>

          <p v-if="attr.meta.type === 'ADDRESS'">
            {{ attr.fullAddress }}
            <a
              :href="`https://www.google.com/maps/search/?api=1&query=${attr.fullAddress}`"
              target="_blank"
              rel="noopener"
            >
              <v-icon icon="place" title="Go to Google Maps (new window)" />
            </a>
            <template v-if="attr.comment"><br />{{ attr.comment }}</template>
          </p>
          <ul v-if="attr.meta.type === 'CHOICE'">
            <li v-for="choice in attr.choices" :key="choice">{{ choice }}</li>
          </ul>
          <ul v-if="attr.meta.type === 'FILE'">
            <view-file-attribute :model-value="attr" />
          </ul>
          <ul v-if="attr.meta.type === 'FLIGHT'">
            <view-flight-attribute :model-value="attr" />
          </ul>
          <p v-if="attr.meta.type === 'INTEGER'">
            {{ attr.integer }}
          </p>
          <ul v-if="attr.meta.type === 'LINK'">
            <li v-for="t in attr.targets" :key="t">
              <a :href="t.target">{{ t.name }}</a>
            </li>
          </ul>
          <v-icon
            v-if="attr.meta.type === 'NOCTURNAL' || attr.meta.type === 'TOGGLE'"
            :icon="attr.active ? 'check_box' : 'check_box_outline_blank'"
            color="primary"
          />
          <p
            v-if="attr.meta.type === 'TEXT'"
            style="white-space: pre-wrap"
            v-html="markdown(attr.text)"
          />
          <ul v-if="attr.meta.type === 'RESOURCE'">
            <li v-for="r in attr.resources" :key="r.id">{{ r.name }}</li>
          </ul>
        </template>
      </template>

      <v-progress-circular v-if="!event" indeterminate :size="100" :width="5" class="progress" />
    </div>

    <notification-failed v-model="failNotification" />

    <update-event
      v-if="updateItem"
      v-model="updateItem"
      @updated="updated()"
      @cancel="updateItem = undefined"
    />

    <delete-dialog v-if="event" :item="event" v-model="askDeleteOpen" @delete="doDelete()" />
    <notification-deleted v-model="deleteNotification" />
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
.event-drawer {
  position: relative;
  padding: 20px 30px;
  font-size: 0.95em;
  height: 100%;

  .event-actions {
    position: absolute;
    top: 1.4em;
    right: 1em;
  }

  .progress {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
  }

  #event-info {
    font-size: 0.95em;
  }

  .toggle {
    .v-selection-control {
      min-height: 35px;
    }
  }

  h3 {
    margin: 0.8em 0 -0.15em 0;
    font-size: 1.15em;
  }
}
</style>
