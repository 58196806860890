<script setup lang="ts">
import type { Event, FlightAttribute, Query } from '@/generated/graphql'
import type { Ref } from 'vue'
import { computed, inject } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { DateTime } from 'luxon'
import { keyEvent } from '@/components/events/shared'
import ViewCommercialFlight from '@/components/travel/ViewCommercialFlight.vue'
import { toDateTime } from '@/app'
import CompareCommercialFlight from '@/components/travel/CompareCommercialFlight.vue'
import { downloadFile } from '@/components/events/attributes/shared'
import gql from 'graphql-tag'

const props = defineProps<{
  modelValue: FlightAttribute
}>()

const event = inject<Ref<Event> | undefined>(keyEvent)!
const mv = computed<FlightAttribute>(() => props.modelValue)

function downloadPdf() {
  const f = mv.value!
  downloadFile(`${f.carrierCode}${f.flightNumber}-${event.value?.name}`, f.pdf, 'application/pdf')
}
</script>

<template>
  <strong>{{ modelValue.carrierCode.toUpperCase() }}{{ modelValue.flightNumber }}</strong>
  <br />

  <template v-if="mv.departureAirportIataCode">
    Departing from {{ mv.departureAirportIataCode }}
  </template>
  <template v-if="mv.departureTerminal"> terminal {{ mv.departureTerminal }}</template>
  <template v-if="mv.departureGate"> gate {{ mv.departureGate }}</template>
  <template v-if="mv.departureDateTimeScheduled">
    at {{ toDateTime(mv.departureDateTimeScheduled) }}
  </template>

  <template v-if="mv.arrivalAirportIataCode">
    Arriving at {{ mv.arrivalAirportIataCode }}
  </template>
  <template v-if="mv.arrivalTerminal"> terminal {{ mv.arrivalTerminal }}</template>
  <template v-if="mv.arrivalGate"> gate {{ mv.arrivalGate }}</template>
  <template v-if="mv.arrivalDateTimeScheduled">
    at {{ toDateTime(mv.arrivalDateTimeScheduled) }}
  </template>
  <br />

  <template v-if="mv.departureDetails">
    <strong>Departure details:</strong> {{ mv.departureDetails }}<br />
  </template>
  <template v-if="mv.bookingReference">
    <strong>Booking reference:</strong> {{ mv.bookingReference }}<br />
  </template>
  <template v-if="mv.baggageDetails">
    <strong>Bagage details:</strong> {{ mv.baggageDetails }}<br />
  </template>
  <template v-if="mv.seatDetails">
    <strong>Seat details:</strong> {{ mv.seatDetails }}<br />
  </template>
  <template v-if="mv.pdf">
    <strong>Tickets</strong>
    <v-btn
      icon="airplane_ticket"
      size="small"
      variant="plain"
      density="comfortable"
      title="Download tickets"
      @click="downloadPdf()"
    />
  </template>
</template>

<style scoped lang="scss">
.download {
  margin: 2px 0;
}
</style>
